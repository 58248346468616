<template>
    <card>
        <h1 slot="title">DefShare - Not Found (404)</h1>
        <div class="container" slot="content">
            <h1 class="text text--title text--center text--danger">{{ localization.title }}</h1>
            <p class="text text--center padding">{{ localization.description }}</p>
        </div>
        <button slot="footer" class="button_submit" v-on:click="uploadForm()">
            {{ localization.uploadFile }}
        </button>
    </card>
</template>

<script>
import Localize from '@localize'
import Card from '@components/Card'

export default {
    components: {
        Card
    }, 
    computed: {
        localization () {
            return Localize.notFoundView()
        }
    },
    methods: {
        uploadForm () {
            this.$router.replace('/')
        }
    }
}
</script>

<style lang="sass">
.text
    font-family: $font-family
    font-size: 1.3rem
    &--center
        text-align: center
    &--title
        font-weight: 600
        font-size: 2rem
    &--danger
        color: $color-error
</style>